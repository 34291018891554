import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import { images } from "../../Config/images";
import "../HomeBooking/HomeBooking.css";
import { useNavigate } from "react-router-dom";

const HomeBooking = ({ noMargin }) => {
  const navigate = useNavigate()
  return (
    <div>
      <div className={`box-postions ${noMargin ? "no-margin" : ""}`}>
        <Grid container spacing={2} className="booking-home">
        
          <Grid item xs={6} sm={6} md={4} lg={3}>
            <Box className="box blue-box" onClick={()=>navigate("/doctor-list")}>
              <img
                src={images.calaender}
                alt="Book An Appointment"
                className="box-image"
              />
              <div className="heading-box">
                <Typography variant="h6" className="box-heading">
                  Book An
                  <br />
                  Appointment
                </Typography>
              </div>
            </Box>
          </Grid>

   
          <Grid item xs={6} sm={6} md={4} lg={3}>
            <Box className="box" onClick={()=>navigate("/doctor-list")}>
              <img src={images.contact1} alt="Doctors" className="box-image" />
              <div className="heading-box">
                <Typography variant="h6" className="box-heading">
                  Doctors
                  <br />
                  <span className="box-text">For your Health Checkup</span>
                </Typography>
              </div>
            </Box>
          </Grid>

       
          <Grid item xs={6} sm={6} md={4} lg={3}>
            <Box className="box" onClick={()=>navigate("/contact")}>
              <img
                src={images.builders}
                alt="Our Hospitals"
                className="box-image"
              />
              <div className="heading-box">
                <Typography variant="h6" className="box-heading">
                  Our Hospitals
                  <br />
                  <span className="box-text">Multispecialty Services</span>
                </Typography>
              </div>
            </Box>
          </Grid>

        
          <Grid item xs={6} sm={6} md={4} lg={3}>
            <a className="box" href="tel:+91 92277 11852">
              <img
                src={images.appoitment}
                alt="Emergency Call"
                className="box-image"
              />
              <div className="heading-box">
                <Typography variant="h6" className="box-heading">
                  Emergency Call
                  <br />
                  <span className="box-text">+91 92277 11852</span>
                  <br />
                  <span className="call-box" style={{ fontSize: "12px" }}>
                    Call for enquiry
                  </span>
                </Typography>
              </div>
            </a>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default HomeBooking;
