import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { FiAlignRight } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import { BiSearch } from "react-icons/bi";
import { Container } from "@mui/material";
import "./Navbar.css";
import { images } from "../../Config/images";

function Navbar() {
  const [containerClass, setContainerClass] = useState("container-fluid");
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  // Handle container class on resize
  const handleResize = () => {
    setContainerClass(
      window.innerWidth >= 1200 ? "container" : "container-fluid"
    );
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Toggle overlay
  const toggleOverlay = () => {
    setIsOverlayVisible(!isOverlayVisible);
  };

  const handleCloseDropdown = () => {
    const dropdown = document.getElementById("navbarDropdown");
    if (dropdown) {
      const dropdownInstance = window.bootstrap.Dropdown.getInstance(dropdown);
      if (dropdownInstance) {
        dropdownInstance.hide();
      }
    }
  };

  // Close offcanvas menu
  const closeOffcanvas = () => {
    const offcanvasElement = document.querySelector(".offcanvas");
    const offcanvasInstance =
      window.bootstrap.Offcanvas.getInstance(offcanvasElement);
    offcanvasInstance.hide();
  };

  // Handle navigation and active link
  const handleNavLinkClick = (path) => {
    navigate(path);
    closeOffcanvas();
  };

  return (
    <>
      <nav className="navbar">
        <div className="navbar-right-wrap">
          <NavLink className="navbar-brand" to="/">
            <img src={images.globallogo2x} alt="global-hospital-logo" className="navbar-logo" />
          </NavLink>
        </div>
        <div className="navbar-search-icon">
          <button
            className="navbar-toggler d-xl-none"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
          >
            <FiAlignRight className="toggler-icon" />
          </button>
        </div>
        <div className="navbar-wrap">
          <div className="navbar-text1 nav-elements">
            <ul className="nav-link-wrap">
              <li className="nav-item dropdown">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <NavLink
                    className="nav-link"
                    to="/aboutus"
                    style={{ marginRight: "8px" }}
                  >
                    ABOUT
                  </NavLink>
                  {/* <button
                    className="btn btn-link dropdown-toggle"
                    id="navbarDropdown"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{
                      padding: 0,
                      margin: 0,
                      fontSize: "10px",
                      border: "none",
                      backgroundColor: "transparent",
                      cursor: "pointer",
                    }}
                  >
                    <i className="fa-solid fa-chevron-down"></i>
                  </button> */}
                </div>

                {/* <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <NavLink
                      className="dropdown-item"
                      to="/division1"
                      onClick={() => handleCloseDropdown()}
                    >
                      Division 1
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="dropdown-item"
                      to="/division2"
                      onClick={() => handleCloseDropdown()}
                    >
                      Division 2
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="dropdown-item"
                      to="/division3"
                      onClick={() => handleCloseDropdown()}
                    >
                      Division 3
                    </NavLink>
                  </li>
                </ul> */}
              </li>

              <li className="nav-item dropdown">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <NavLink
                    className="nav-link"
                    to="/specialties"
                    style={{ marginRight: "8px" }}
                  >
                    SPECIALTIES
                  </NavLink>
                  {/* <button
                    className="btn btn-link dropdown-toggle"
                    id="navbarDropdown"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{
                      padding: 0,
                      margin: 0,
                      fontSize: "10px",
                      border: "none",
                      backgroundColor: "transparent",
                      cursor: "pointer",
                    }}
                  >
                    <i className="fa-solid fa-chevron-down"></i>
                  </button> */}
                </div>

                {/* <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <NavLink
                      className="dropdown-item"
                      to="/division1"
                      onClick={() => handleCloseDropdown()}
                    >
                      Division 1
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="dropdown-item"
                      to="/division2"
                      onClick={() => handleCloseDropdown()}
                    >
                      Division 2
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="dropdown-item"
                      to="/division3"
                      onClick={() => handleCloseDropdown()}
                    >
                      Division 3
                    </NavLink>
                  </li>
                </ul> */}
              </li>
              <li className="nav-item dropdown">
                <NavLink
                  className="nav-link dropdown-toggle"
                  to="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ display: "flex", alignItems: "center", pointerEvents: "none" }}
                >
                  SERVICES
                  {/* <i
                    className="fa-solid fa-chevron-down"
                    style={{
                      marginLeft: "8px",
                      fontSize: "10px",
                    }}
                  ></i> */}
                </NavLink>
                {/* 
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <NavLink className="dropdown-item" to="/division1">
                      Services 1
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/division2">
                      Services 2
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/division3">
                      Services 3
                    </NavLink>
                  </li>
                </ul> */}
              </li>
              <li className="nav-item">
                <NavLink to="/blog" className="nav-link" style={{ pointerEvents: "none" }}>
                  DEPARTMENT
                </NavLink>
              </li>
              <li className="nav-item dropdown">
                <NavLink
                  className="nav-link dropdown-toggle"
                  to="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ display: "flex", alignItems: "center", pointerEvents: "none" }}
                >
                  INTERNATIONAL PATIENT
                  {/* <i
                    className="fa-solid fa-chevron-down"
                    style={{
                      marginLeft: "8px",
                      fontSize: "10px",
                    }}
                  ></i> */}
                </NavLink>

                {/* <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <NavLink className="dropdown-item" to="/division1">
                      Investor 1
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/division2">
                      Investor 2
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/division3">
                      Investor 3
                    </NavLink>
                  </li>
                </ul> */}
              </li>
              <li className="nav-item">
                <NavLink to="/contact" className="nav-link">
                  CONTACT
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <div
          className="offcanvas offcanvas-start"
          tabIndex="-1"
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
        >
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
              <img src={images.globallogo2x} alt="global-hospital-logo-2x" className="navbar-logo" />
            </h5>
            <button
              type="button"
              className="text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              <IoMdClose className="offcanvas-close-btn" />
            </button>
          </div>
          <div className="offcanvas-body nav-elements">
            <ul className="navbar-nav">
              {/* <li className="nav-item">
                <a
                  className="nav-link"
                 href="/"
                >
                  Home
                </a>
              </li> */}
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/aboutus">
                  ABOUT
                </NavLink>

                {/* <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <NavLink className="dropdown-item" to="/division1">
                      Division 1
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/division2">
                      Division 2
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/division3">
                      Division 3
                    </NavLink>
                  </li>
                </ul> */}
              </li>

              <li className="nav-item dropdown">
                <NavLink
                  className="nav-link dropdown-toggle"
                  to="/specialties"

                >
                  SPECIALTIES
                  {/* <i
                    className="fa-solid fa-chevron-down"
                    style={{
                      marginLeft: "8px",
                      fontSize: "10px",
                    }}
                  ></i> */}
                </NavLink>

                {/* <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <NavLink className="dropdown-item" to="/division1">
                      Division 1
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/division2">
                      Division 2
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/division3">
                      Division 3
                    </NavLink>
                  </li>
                </ul> */}
              </li>
              <li className="nav-item dropdown">
                <NavLink
                  className="nav-link dropdown-toggle"
                  to="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ display: "flex", alignItems: "center", pointerEvents: "none" }}
                >
                  SERVICES
                  {/* <i
                    className="fa-solid fa-chevron-down"
                    style={{
                      marginLeft: "8px",
                      fontSize: "10px",
                    }}
                  ></i> */}
                </NavLink>

                {/* <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <NavLink className="dropdown-item" to="/division1">
                      Services 1
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/division2">
                      Services 2
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/division3">
                      Services 3
                    </NavLink>
                  </li>
                </ul> */}
              </li>
              <li className="nav-item">
                <NavLink to="/blog" className="nav-link" style={{ pointerEvents: "none" }}>
                  DEPARTMENT
                </NavLink>
              </li>
              <li className="nav-item dropdown">
                <NavLink
                  className="nav-link dropdown-toggle"
                  to="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ display: "flex", alignItems: "center", pointerEvents: "none" }}
                >
                  INTERNATIONAL PATIENT
                  {/* <i
                    className="fa-solid fa-chevron-down"
                    style={{
                      marginLeft: "8px",
                      fontSize: "10px",
                    }}
                  ></i> */}
                </NavLink>

                {/* <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <NavLink className="dropdown-item" to="/division1">
                      Investor 1
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/division2">
                      Investor 2
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/division3">
                      Investor 3
                    </NavLink>
                  </li>
                </ul> */}
              </li>
              <li className="nav-item">
                <NavLink to="/contact" className="nav-link">
                  CONTACT
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
