import React from "react";
import {
  Container,
  Grid2,
  Typography,
  TextField,
  Button,
  Box,
  Grid,
} from "@mui/material";
import "./Contact.css";
import { images } from "../../Config/images";
import HomeBooking from "../../componant/HomeBooking/HomeBooking";
import CommonBreadcrumbs from "../../componant/Breadcrumb/Breadcrumb";
import Booking from "../../componant/Booking/Booking";

const Contact = () => {
  const contactNumbers = [
    {
      name: "Reception: ",
      number: "+91 9227711855/56",
    },
    {
      name: "Emergency: ",
      number: "+91 92277 11852",
    },
    {
      name: "Appointment: ",
      number: "+91 92277 11854",
    },
  ];

  const openingHours = [
    {
      name: "OPD Timings: ",
      timing: "9 am to 8 pm",
    },
    {
      name: "Emergency: ",
      timing: "24*7 hours",
    },
  ];

  const breadcrumbsData = [
    { label: "Home", url: "/" },
    {
      label: "Contact us",
      url: "/contact",
    },
  ];

  return (
    <>
      <div>
        <div className="header-image">
          <div className="absolute-image-container">
            <img
              src={images.contactslider} // Replace with your image source
              alt="Doctors"
              className="absolute-image"
            />
            <div className="image-text-about">
              <h1 className="text-about">Contact Us</h1>
              <p className="text-health">
                Let’s Stay Connected <br />
                Reach Out to Global Hospital Anytime.
              </p>
              <div className="breadcrumb">
                <CommonBreadcrumbs items={breadcrumbsData} separator="›" />
              </div>
            </div>
          </div>
        </div>

        {/* <div className="relative-image-container">
       
          <img
            src={images.doctores} // Replace with your image source
            alt="Doctors"
            className="relative-image"
          />
          <div className="image-text-about">
            <h1 className="text-about">About Us</h1>
            <p className="text-health">
              Total Health Solutions, Across the Globe.
            </p>
            <div className="breadcrumb">
              <CommonBreadcrumbs items={breadcrumbsData} separator="›" />
            </div>
          </div>
        </div> */}
      </div>

      <div>
        <HomeBooking noMargin />
      </div>
      <div className="contact-div">
        <Grid container spacing={4}>
          {/* Contact Information Section */}
          <Grid item xs={12} md={7} sm={12}>
            <Box className="ContactDetail-box">
              <Box className="mt-5 d-flex justify-content-between">
                <Grid container spacing={2}>
                  <Grid item md={6} sm={12} xs={12}>
                    <div className="mb-3">
                      <img
                        src={images?.locationInfo}
                        alt="open-hours"
                        className="mb-1"
                      />
                    </div>
                    <h6 className="contact-title">Address</h6>
                    <p className="contact-add">
                      Opp. Auda garden, Nr. Sindhubhavan Road, Off S.G. Highway,
                      Bodakdev, Ahmedabad, Gujarat - 380054
                    </p>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <div className="mb-3">
                      <img
                        src={images?.customer}
                        alt="open-hours"
                        className="mb-1"
                      />
                    </div>
                    <h6 className="contact-title">Contact No</h6>
                    <div>
                      <div className="d-flex gap-4">
                        <p className="contact-add margin-bottom-5px">
                          Reception:
                        </p>
                        <p className="contact-add margin-bottom-5px">
                          <a href="tel:+919227711855" className="anchorBlack contact-numbers">+91 92277 11855</a>/<a href="tel:+919227711856" className="anchorBlack contact-numbers">56</a>
                        </p>
                      </div>
                      <div className="d-flex gap-3">
                        <p className="contact-add margin-bottom-5px">
                          Emergency:
                        </p>
                        <p className="contact-add margin-bottom-5px">
                          <a href="tel:+919227711852" className="anchorBlack contact-numbers">+91 92277 11852</a>
                        </p>
                      </div>
                      <div className="d-flex gap-2">
                        <p className="contact-addmargin-bottom-5px">
                          Appointment:
                        </p>
                        <p className="contact-add margin-bottom-5px">
                          <a href="tel:+919227711854" className="anchorBlack contact-numbers">+91 92277 11854</a>
                        </p>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Box>
              <Box className="mt-5 d-flex justify-content-between">
                <Grid container spacing={2}>
                  <Grid item md={6} sm={12} xs={12}>
                    <div className="mb-3">
                      <img
                        src={images?.openHours}
                        alt="open-hours"
                        className="mb-1"
                      />
                    </div>
                    <h6 className="contact-title">Opening Hours</h6>
                    <div className="mt-3">
                      {openingHours.map((openingHour) => (
                        <div className="d-flex gap-1" key={openingHour?.name}>
                          <p
                            className="contact-add"
                            style={{ marginBottom: "5px" }}
                          >
                            {openingHour?.name} {openingHour?.timing}
                          </p>
                        </div>
                      ))}
                    </div>
                  </Grid>
                  <Grid item md={6}>
                    <div className="mb-3">
                      <img
                        src={images?.emailData}
                        alt="mail-info"
                        className="mb-1"
                      />
                    </div>
                    <h6 className="contact-title">Mail</h6>
                    <p className="contact-add" style={{ marginBottom: "5px" }}>
                      <p style={{ marginBottom: "5px" }}>
                        <a href="mailto:info@globalhospital.co" className="anchorBlack">info@globalhospital.co</a>
                      </p>
                    </p>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>

          {/* Emergency Form Section */}
          <Grid item xs={12} md={5} sm={12}>
            <Box
              className="shadow rounded-4 border border-primary-subtle"
              sx={{ height: "550px" }}
            >
              <Typography
                variant="h4"
                sx={{
                  height: "80px",
                  backgroundColor: "#354895",
                  color: "#ffffff",
                  fontWeight: 400,
                  fontSize: "36px",
                  lineHeight: "50.76px",
                  letterSpacing: "2%",
                  padding: "1rem 0 0 1.5rem ",
                  borderTopLeftRadius: "12px",
                  borderTopRightRadius: "12px",
                  marginBottom: "1rem",
                }}
                className="contact-form-title"
              >
                Need emergency?
              </Typography>
              <form>
                <div className="contact-form-div">
                  <input
                    type={"text"}
                    placeholder="Enter your name"
                    className="contact-input"
                  />
                </div>
                <div className="contact-form-div">
                  <input
                    type={"text"}
                    placeholder="Enter email id"
                    className="contact-input"
                  />
                </div>
                <div className="contact-form-div">
                  <input
                    type={"text"}
                    placeholder="Enter mobile number"
                    className="contact-input"
                  />
                </div>
                <div className="contact-form-div">
                  <textarea
                    rows={36}
                    cols={20}
                    type={"text"}
                    placeholder="Write your message"
                    className="contact-input-textarea"
                  />
                </div>
                <div className="contact-form-div">
                  <button className="contact-submit-btn">Submit Now</button>
                </div>
              </form>
            </Box>
          </Grid>
        </Grid>
        <div className="mt-5 contactDatamap" style={{ width: "100%" }}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.524960554315!2d72.50872609999999!3d23.041207999999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9b4f80a8d1bf%3A0x921f95d936981600!2sGlobal%20Hospital!5e0!3m2!1sen!2sin!4v1733205175769!5m2!1sen!2sin"
            width="1155"
            height="645"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>

        </div>

      </div>
      <div>
        <Booking />
      </div>
    </>
  );
};

export default Contact;
