import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import "./Breadcrumb.css";

function handleClick(event) {
  console.info("clicked a breadcrumb");
}

function Breadcrumb({ items, separator }) {
  const breadcrumbs = items.map((item, index) => {
    if (item.url) {
      return (
        <Link
          key={index}
          underline="hover"
          color="inherit"
          href={item.url}
          onClick={handleClick}
        >
          {item.label}
        </Link>
      );
    } else if (React.isValidElement(item)) {
      return React.cloneElement(item, { key: index });
    }
  });

  return (
    <>
      <div className="breadcrumbs">
        <Breadcrumbs separator={separator} aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </div>
    </>
  );
}

export default Breadcrumb;
