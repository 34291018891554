import React from "react";
import { Container, Grid, Typography, Link } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import "../Footer/Footer.css"; // Ensure this file contains the required styles
import { images } from "../../Config/images";

const Footer = () => {
  return (
    <>
      <div className="footer-wrapper">
        <footer className="footer">
          {/* Main Sections */}
          <Grid container spacing={4}>
            {/* Section 1: About */}
            <Grid item xs={12} sm={6} md={4} className="footer-section">
              <img
                src={images.footerlogo}
                alt="Global Hospital Logo"
                className="footer-logo mt-4"
              />
              <Typography className="footer-description mt-4">
                Visit us for world-class medical services tailored to your needs
                – your health, our priority.
              </Typography>
              <div className="social-icons mt-5">
                <Link
                  href="https://www.facebook.com/GlobalHospitalsOfficial"
                  aria-label="Facebook"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa-brands fa-facebook-f"></i>
                </Link>
                <Link
                  href="https://www.youtube.com/@GLOBALHOSPITALSOFFICIAL"
                  aria-label="YouTube"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa-brands fa-youtube"></i>
                </Link>
                <Link
                  href="https://www.instagram.com/globalhospitalsofficial/profilecard/"
                  aria-label="Instagram"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa-brands fa-instagram"></i>
                </Link>
                <Link
                  href="https://www.linkedin.com/company/global-hospitals-official/"
                  aria-label="LinkedIn"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa-brands fa-linkedin-in"></i>
                </Link>
              </div>
            </Grid>

            {/* Section 2: Quick Links */}
            <Grid item xs={12} sm={6} md={3} className="footer-section">
              <Typography variant="h6" className="footer-title mt-4 mx-4">
                QUICK LINK
              </Typography>
              <ul className="footer-links mx-4">
                <li>
                  <Link href="AboutUs">ABOUT</Link>
                </li>
                <li>
                  <Link href="specialties">SPECIALTIES</Link>
                </li>
                <li>
                  <Link href="#">SERVICES</Link>
                </li>
                <li>
                  <Link href="#">DEPARTMENT</Link>
                </li>
                <li>
                  <Link href="#">INTERNATIONAL PATIENT</Link>
                </li>
                <li>
                  <Link href="Contact">CONTACT</Link>
                </li>
              </ul>
            </Grid>

            {/* Section 3: Contact Us */}
            <Grid item xs={12} sm={12} md={5} className="footer-section">
              <Typography variant="h6" className="footer-title mt-4 mx-4" style={{paddingLeft:"5px"}}>
                CONTACT US
              </Typography>
              <ul className="footer-contact-list">
                <li className="footer-contact">
                  <Grid container>
                    <Grid xs={12} md={4} lg={4} sm={12}>
                      {" "}
                      <Typography variant="subtitle1">LOCATION :</Typography>
                    </Grid>
                    <Grid xs={12} md={8} lg={8} sm={12}>
                      <Typography>
                      Nr.Bodakbev Garden, Pakwan Cross Road, Nr.sindhubhavan Road, Off. S.G. Highway, Bodakdev, Ahmedabad-380054. Gujarat
                      </Typography>
                    </Grid>
                  </Grid>
                </li>
                <li className="footer-contact">
                  <Grid container>
                    <Grid xs={12} md={4} lg={4} sm={12}>
                      {" "}
                      <Typography variant="subtitle1">RECEPTION :</Typography>
                    </Grid>
                    <Grid xs={12} md={8} lg={8} sm={12}>
                      <Typography>
                        <a
                          href="tel:+919227711855"
                          className="footer-link anchor"
                        >
                          +91 92277 11855
                        </a>
                        /
                        <a
                          href="tel:+919227711856"
                          className="footer-link anchor"
                        >
                          56
                        </a>
                      </Typography>
                    </Grid>
                  </Grid>
                </li>
                <li className="footer-contact">
                  <Grid container>
                    <Grid xs={12} md={4} lg={4} sm={12}>
                      {" "}
                      <Typography variant="subtitle1">APPOINTMENT :</Typography>
                    </Grid>
                    <Grid xs={12} md={8} lg={8} sm={12}>
                      <Typography>
                        <a
                          href="tel:+919227711854"
                          className="footer-link anchor"
                        >
                          +91 92277 11854
                        </a>
                      </Typography>
                    </Grid>
                  </Grid>
                </li>
                <li className="footer-contact">
                  <Grid container>
                    <Grid xs={12} md={4} lg={4} sm={12}>
                      {" "}
                      <Typography variant="subtitle1">EMERGENCY :</Typography>
                    </Grid>
                    <Grid xs={12} md={8} lg={8} sm={12}>
                      <Typography>
                        <a
                          href="tel:+919227711852"
                          className="footer-link anchor"
                        >
                          +91 92277 11852
                        </a>
                      </Typography>
                    </Grid>
                  </Grid>
                </li>
                <li className="footer-contact">
                  <Grid container>
                    <Grid xs={12} md={4} lg={4} sm={12}>
                      {" "}
                      <Typography variant="subtitle1">EMAIL :</Typography>
                    </Grid>
                    <Grid xs={12} md={8} lg={8} sm={12}>
                      <Typography>
                        <a
                          href="mailto:info@globalhospital.co"
                          className="footer-link anchor"
                        >
                          info@globalhospital.co
                        </a>
                      </Typography>
                    </Grid>
                  </Grid>
                </li>
              </ul>
            </Grid>
          </Grid>

          {/* Footer Bottom */}
          <div className="footer-bottom">
            <Typography>© Copyright 2024. All Rights Reserved.</Typography>
            <Typography className="footer-developer">
              Design And Developed by{" "}
              <Link
                href="https://srashtasoft.com/"
                target="_blank"
                className="developer-link"
                style={{
                  color: "white",
                  textDecoration: "none",
                  fontWeight: "600",
                }}
              >
                Srashtasoft
              </Link>
              <Link
                href="#top"
                className="back-to-top mx-2"
                aria-label="Back to top"
              >
                <ArrowUpwardIcon className="arrow-icon" />
              </Link>
            </Typography>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Footer;
