import React from "react";
import "../Specialties/Specialties.css";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Pagination,
  Typography,
} from "@mui/material";
import cardiogram from "../../assets/images/cardiogram.png";
import Stack from "@mui/material/Stack";
import { images } from "../../Config/images";
import CommonBreadcrumbs from "../../componant/Breadcrumb/Breadcrumb";
import HomeBooking from "../../componant/HomeBooking/HomeBooking";
import { specialitiesIcons, specialitiesIconsNew } from "../../Config/images"; // Importing image assets (if needed)
import { styled } from "@mui/material/styles";

const specialties = [
  { name: "General & Minimal Access Surgery", image: specialitiesIcons.surgeryRoom, alt: "General & Minimal Access Surgery" },
  { name: "Surgical Gastroenterology", image: specialitiesIcons.gastro, alt: "Surgical Gastroenterology" },
  { name: "Medical Gastroenterology", image: specialitiesIcons.ctScan, alt: "Medical Gastroenterology" },
  { name: "General Medicine", image: specialitiesIcons.medicine, alt: "General Medicine" },
  { name: "Surgical Oncology", image: specialitiesIcons.roboticSurgery, alt: "Surgical Oncology" },
  { name: "Medical Oncology", image: specialitiesIcons.medical, alt: "Medical Oncology" },
  { name: "Urosurgery", image: specialitiesIcons.psychology, alt: "Urosurgery" },
  { name: "Nephrology", image: specialitiesIcons.kidneys, alt: "Nephrology" },
  { name: "Orthopaedics & Joint Replacement", image: specialitiesIcons.painInJoints, alt: "Orthopaedics & Joint Replacement" },
  { name: "Neuro & Spine Surgery", image: specialitiesIcons.mentalState, alt: "Neuro & Spine Surgery" },

  { name: "Anesthesiology", image: specialitiesIconsNew.anesthesiology, alt: "Anesthesiology" },
  { name: "Aesthetics", image: specialitiesIconsNew.aesthetics, alt: "Aesthetics" },
  { name: "Audiology & Speech Therapy", image: specialitiesIconsNew.audiologyAndSpeechTherapy, alt: "Audiology & Speech Therapy" },
  { name: "Cancer Care", image: specialitiesIconsNew.cancer, alt: "Cancer Care" },
  { name: "Cardiology", image: specialitiesIconsNew.cardiology, alt: "Cardiology" },
  { name: "Cardiovascular Surgery", image: specialitiesIconsNew.cardiovascularSurgery, alt: "Cardiovascular Surgery" },
  { name: "Cranio Maxillofacial Surgery", image: specialitiesIconsNew.cranioMaxillofacialSurgery, alt: "Cranio Maxillofacial Surgery" },
  { name: "Critical Care", image: specialitiesIconsNew.criticalCare, alt: "Critical Care" },
  { name: "Dental Sciences", image: specialitiesIconsNew.dentalSciences, alt: "Dental Sciences" },
  { name: "Dermatology & Cosmetology", image: specialitiesIconsNew.dermatologyAndCosmetology, alt: "Dermatology & Cosmetology" },
  { name: "Diabetology", image: specialitiesIconsNew.diabetology, alt: "Diabetology" },
  { name: "ENT (Ear, Nose & Throat)", image: specialitiesIconsNew.ent, alt: "ENT" },
  { name: "Emergency", image: specialitiesIconsNew.ambulance, alt: "Emergency" },
  { name: "Endocrinology", image: specialitiesIconsNew.endocrinology, alt: "Endocrinology" },
  { name: "General Medicine", image: specialitiesIconsNew.generalMedicine, alt: "General Medicine" },
  { name: "General & Minimal Access Surgery", image: specialitiesIconsNew.generalAndMinimalAccessSurgery, alt: "General Surgery" },
  { name: "HPB Surgery", image: specialitiesIconsNew.hpbSurgery, alt: "HPB Surgery" },
  { name: "Infectious Disease", image: specialitiesIconsNew.infectiousDisease, alt: "Infectious Disease" },
  { name: "Interventional Radiology", image: specialitiesIconsNew.interventionalRadiology, alt: "Interventional Radiology" },
  { name: "Laboratory", image: specialitiesIconsNew.laboratory, alt: "Laboratory" },
  { name: "Medical Gastroenterology", image: specialitiesIconsNew.surgicalGastroenterology, alt: "Medical Gastroenterology" },
  { name: "Nephrology", image: specialitiesIconsNew.nephrology, alt: "Nephrology" },
  { name: "Neuro & Spine Surgery", image: specialitiesIconsNew.neuroAndSpineSurgery, alt: "Neuro & Spine Surgery" },
  { name: "Neurology", image: specialitiesIconsNew.psychological, alt: "Neurology" }, // Reusing as no separate Neurology icon was mentioned
  { name: "Nutrition & Dietetics", image: specialitiesIconsNew.nutritionAndDietitics, alt: "Nutrition & Dietetics" },
  { name: "Obstetrics & Gynaecology", image: specialitiesIconsNew.obstetricsAndGynaecology, alt: "Obstetrics & Gynaecology" },
  { name: "Ophthalmology", image: specialitiesIconsNew.ophthalmology, alt: "Ophthalmology" },
  { name: "Orthopaedics & Joint Replacement", image: specialitiesIconsNew.orthopaedicsAndJointReplacement, alt: "Orthopaedics & Joint Replacement" },
  { name: "Paediatrics", image: specialitiesIconsNew.paediatrics, alt: "Paediatrics" },
  { name: "Pain Management", image: specialitiesIconsNew.painManagement, alt: "Pain Management" },
  { name: "Physiotherapy", image: specialitiesIconsNew.physiotherapy, alt: "Physiotherapy" },
  { name: "Plastic Cosmetic & Reconstructive Surgery", image: specialitiesIconsNew.plasticCosmeticReconstructiveSurgery, alt: "Plastic Surgery" },
  { name: "Psychiatry", image: specialitiesIconsNew.psychiatry, alt: "Psychiatry" },
  { name: "Pulmonology & Interventional Pulmonology", image: specialitiesIconsNew.pulmonologyInterventionalPulmonology, alt: "Pulmonology" },
  { name: "Rheumatology", image: specialitiesIconsNew.rheumatology, alt: "Rheumatology" },
  { name: "Surgical Gastroenterology", image: specialitiesIconsNew.surgicalGastroenterology2, alt: "Surgical Gastroenterology" },
  { name: "Surgical Oncology", image: specialitiesIconsNew.surgicalOncology, alt: "Surgical Oncology" },
  { name: "Urosurgery", image: specialitiesIconsNew.urosurgery, alt: "Urosurgery" },
  { name: "Urogynaecology", image: specialitiesIconsNew.urogynaecology, alt: "Urogynaecology" },
  { name: "Vascular & Endovascular Surgery", image: specialitiesIconsNew.vascularAndEndovascularSurgery, alt: "Vascular Surgery" },
];


const Specialties = () => {
  const breadcrumbsData = [
    { label: "Home", url: "/" },
    {
      label: "Specialist",
      url: "/specialties",
    },
  ];

  const chunkSize = 6;
  const groupedSpecialties = [];
  for (let i = 0; i < specialties.length; i += chunkSize) {
    groupedSpecialties.push(specialties.slice(i, i + chunkSize));
  }

  const CustomPagination = styled(Pagination)(({ theme }) => ({
    "& .MuiPaginationItem-root": {
      borderColor: "var(--secondary-color)",
      borderWidth: "1px",
      borderStyle: "solid",
      marginTop: "30px",
    },
    "& .Mui-selected": {
      borderColor: "var(--secondary-color)",
      backgroundColor: "var(--secondary-color)  !important",
      color: "var(--white)",
    },
  }));

  return (
    <div>
      <div className="header-image">
        <div className="absolute-image-container">
          <img
            src={images.contactslider} // Replace with your image source
            alt="Doctors"
            className="absolute-image"
          />
          <div className="image-text-about">
            <h1 className="text-about">Specialties</h1>
            <p className="text-health">
              Wide range of Specialties <br />
              facilities under one roof
            </p>
            <div className="breadcrumb">
              <CommonBreadcrumbs items={breadcrumbsData} separator="›" />
            </div>
          </div>
        </div>
      </div>
      <HomeBooking noMargin />
      <div className="specialties-section mb-5">

        {/* Header Section */}
        <div className="mt-5 mb-3">
          <h2 className="specialties-title">Key Specialties</h2>
          <div className="healthcare">
            <p className="personalized-text">
              Personalized Healthcare Solutions <br />
              Designed Just For You
            </p>
            {/* <button variant="outlined" className="view-all-btn">
              View All
            </button> */}
          </div>
        </div>

        {/* Grid Section */}

        <div className="mt-4">
          {groupedSpecialties.map((group, groupIndex) => (
            <Grid
              container
              spacing={3}
              justifyContent="center"
              key={`group-${groupIndex}`}
            >
              {group.map((specialty, index) => (
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={2}
                  lg={2}
                  key={`specialty-${groupIndex}-${index}`}
                  sx={{
                    marginBottom: index % 5 === 4 ? "20px" : "0", // Adds margin after every 5th item
                  }}
                >
                  <Box className="specialties-box">
                    <img
                      src={specialty.image}
                      alt={specialty.alt}
                      className="special-image"
                    />
                    <Typography variant="h6" className="specialty-heading">
                      {specialty.name}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          ))}
        </div>

      </div>

      <Stack
        spacing={2}
        justifyContent="center"
        alignItems="center" // Centers the content horizontally
        className="mt-5 mb-5 d-flex pagination-stack"
        sx={{
          padding: "16px", // Optional padding for spacing
          borderRadius: "8px", // Optional rounded corners
        }}
      >
        <CustomPagination
          count={1}
          sx={{
            "& .MuiPaginationItem-ellipsis": {
              color: "#01ADEC", // Apply color to the ellipsis (if any)
            },
            "& .MuiPaginationItem-root.Mui-selected": {
              backgroundColor: "#01ADEC", // Background color when a page is selected
              color: "#fff", // White text when selected
            },
          }}
        />
      </Stack>
    </div>
  );
};

export default Specialties;
