import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "Albert Sans, sans-serif",
    h1: {
      fontFamily: "Albert Sans, sans-serif",
    },
    h2: {
      fontFamily: "Albert Sans, sans-serif",
    },
    h3: {
      fontFamily: "Albert Sans, sans-serif",
    },
    h4: {
      fontFamily: "Albert Sans, sans-serif",
    },
    h5: {
      fontFamily: "Albert Sans, sans-serif",
    },
    h6: {
      fontFamily: "Albert Sans, sans-serif",
    },
  },
});

export default theme;
