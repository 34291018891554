import React, { useEffect, useState } from "react";
import { images } from "../../Config/images"; // Importing image assets (if needed)
import { specialitiesIcons, specialitiesIconsNew } from "../../Config/images"; // Importing image assets (if needed)
import "../Home/Home.css"; // Ensure the correct path to your CSS file
import {
  TextField,
  InputAdornment,
  Grid2,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import HomeBooking from "../../componant/HomeBooking/HomeBooking";

import Slider from "react-slick"; // Assuming you're using react-slick for the slider
import Booking from "../../componant/Booking/Booking";
import { Link } from "react-router-dom";

const Home = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const updateMobileView = () => {
      setIsMobile(window.innerWidth <= 1400); // Adjust breakpoint as needed
    };

    updateMobileView(); // Initial check
    window.addEventListener("resize", updateMobileView); // Listen for resize events

    return () => window.removeEventListener("resize", updateMobileView);
  }, []);

  const settings = {
    dots: !isMobile,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    appendDots: (dots) => (
      <div className="custom-dots">
        <ul>{dots}</ul>
      </div>
    ),
    customPaging: () => <div className="dot" />,
  };

  return (
    <>
      <div className="home-booking-container-fluid">
        <div className="home-booking-container">
          <div className="slider-container">
            <Slider {...settings}>
              <div>
                <img
                  src={images.sliderNew1}
                  alt="Slider 1"
                  className="slider-image"
                />
              </div>
              <div>
                <img
                  src={images.sliderNew2}
                  alt="Slider 2"
                  className="slider-image"
                />
              </div>
              {/* Add more slides as needed */}
            </Slider>
            <div className="slider-text">
              <h1 className="slider-heading mt-5 pt-2">
                On Your Side, <br />
                For Every Step Of Your <br /> Healthcare Journey
              </h1>
              <p className="slider-p mt-4 ">We are Global Hospital</p>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Search for Doctors, Specialties, and Hospitals"
                className="search-field-11 mt-3 mb-4"
                InputProps={{
                  startAdornment: null, // Remove the startAdornment if you had one
                  // Add endAdornment for the icon to appear at the end
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <HomeBooking />
      </div>

      <div className="container-fluid specialties-section mb-5">
        {/* Header Section */}
        <div className="mt-5 mb-3 key-spec-sec">
          <h2 className="specialties-title">Key Specialties</h2>
          <div className="healthcare">
            <p className="personalized-text">
              Personalized Healthcare Solutions <br />
              Designed Just For You
            </p>
            <button variant="outlined" className="view-all-btn">
              <a href="/specialties">View All</a>
            </button>
          </div>
        </div>

        {/* Grid Section */}
        <div className=" mt-4 ">
          <Grid container spacing={3} justifyContent="center">
            {/* Cardiology Box */}
            <Grid item xs={6} sm={6} md={4} lg={2}>
              <Box className="specialties-box">
                <img
                  src={specialitiesIcons.surgeryRoom}
                  alt="Cardiology"
                  className="special-image"
                />
                <Typography variant="h6" className="specialty-heading">
                  General & Minimal Access Surgery
                </Typography>
              </Box>
            </Grid>

            {/* Dermatology Box */}
            <Grid item xs={6} sm={6} md={4} lg={2}>
              <Box className="specialties-box">
                <img
                  src={specialitiesIcons.gastro}
                  alt="Dermatology"
                  className="special-image"
                />
                <Typography variant="h6" className="specialty-heading">
                  Surgical Gastroenterology
                </Typography>
              </Box>
            </Grid>

            {/* Physiotherapy Box */}
            <Grid item xs={6} sm={6} md={4} lg={2}>
              <Box className="specialties-box">
                <img
                  src={specialitiesIcons.ctScan}
                  alt="Physiotherapy"
                  className="special-image"
                />
                <Typography variant="h6" className="specialty-heading">
                  Medical Gastroenterology
                </Typography>
              </Box>
            </Grid>

            {/* Orthopaedics Box */}
            <Grid item xs={6} sm={6} md={4} lg={2}>
              <Box className="specialties-box">
                <img
                  src={specialitiesIcons.medicine}
                  alt="Orthopaedics"
                  className="special-image"
                />
                <Typography variant="h6" className="specialty-heading">
                  General Medicine
                </Typography>
              </Box>
            </Grid>

            {/* Add New Box */}
            <Grid item xs={6} sm={6} md={4} lg={2}>
              <Box className="specialties-box">
                <img
                  src={specialitiesIcons.roboticSurgery}
                  alt="New Specialty"
                  className="special-image"
                />
                <Typography variant="h6" className="specialty-heading">
                  Surgical Oncology
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={2}>
              <Box className="specialties-box">
                <img
                  src={specialitiesIcons.roboticSurgery}
                  alt="New Specialty"
                  className="special-image"
                />
                <Typography variant="h6" className="specialty-heading">
                  Surgical Oncology
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
        <div className=" mt-4 ">
          <Grid container spacing={3} justifyContent="center">
            {/* Cardiology Box */}
            <Grid item xs={6} sm={6} md={4} lg={2}>
              <Box className="specialties-box">
                <img
                  src={specialitiesIcons.medical}
                  alt="Cardiology"
                  className="special-image"
                />
                <Typography variant="h6" className="specialty-heading">
                  Medical Oncology
                </Typography>
              </Box>
            </Grid>

            {/* Dermatology Box */}
            <Grid item xs={6} sm={6} md={4} lg={2}>
              <Box className="specialties-box">
                <img
                  src={specialitiesIcons.psychology}
                  alt="Dermatology"
                  className="special-image"
                />
                <Typography variant="h6" className="specialty-heading">
                  Urosurgery
                </Typography>
              </Box>
            </Grid>

            {/* Physiotherapy Box */}
            <Grid item xs={6} sm={6} md={4} lg={2}>
              <Box className="specialties-box">
                <img
                  src={specialitiesIcons.kidneys}
                  alt="Physiotherapy"
                  className="special-image"
                />
                <Typography variant="h6" className="specialty-heading">
                  Nephrology
                </Typography>
              </Box>
            </Grid>

            {/* Orthopaedics Box */}
            <Grid item xs={6} sm={6} md={4} lg={2}>
              <Box className="specialties-box">
                <img
                  src={specialitiesIcons.painInJoints}
                  alt="Orthopaedics"
                  className="special-image"
                />
                <Typography variant="h6" className="specialty-heading">
                  Orthopaedics & Joint Replacement
                </Typography>
              </Box>
            </Grid>

            {/* Add New Box */}
            <Grid item xs={6} sm={6} md={4} lg={2}>
              <Box className="specialties-box">
                <img
                  src={specialitiesIcons.mentalState}
                  alt="New Specialty"
                  className="special-image"
                />
                <Typography variant="h6" className="specialty-heading">
                  Neuro & Spine Surgery
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={2}>
              <Box className="specialties-box">
                <img
                  src={specialitiesIconsNew.nutritionAndDietitics}
                  alt="Nutrition & Dietetics"
                  className="special-image"
                />
                <Typography variant="h6" className="specialty-heading">
                  Nutrition & Dietetics
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>

      <div class="container-fluid key-facts-container mb-5">
        <Grid container spacing={4}>
          <Grid item lg={7}>
            <h3>KEY FACTS</h3>

            <h1 className="mt-3">
              Essential Insights At A Glance
              <br />
              Explore Our Key Facts.
            </h1>
            <Grid container spacing={2} className="mt-5">
              <Grid item lg={4} md={4} xs={6}>
                <div className="fact-box">
                  <img src={images.medicalstaff} alt="Medical Staff Icon" />
                  <h2>50+</h2>
                  <p>Medical staff</p>
                </div>
              </Grid>
              <Grid item lg={4} md={4} xs={6}>
                <div className="fact-box">
                  <img src={images.icubed} alt="Beds Icon" />
                  <h2>75+</h2>
                  <p>Beds</p>
                </div>
              </Grid>
              <Grid item lg={4} md={4} xs={6}>
                <div className="fact-box">
                  <img src={images.hospitalbed} alt="ICU Bed Icon" />
                  <h2>25+</h2>
                  <p>Bed ICU</p>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={5} style={{ textAlign: "end" }}>
            {" "}
            <img
              src={images.keyreactgle}
              alt="Tablet View"
              class="img-fluid key-fact-img"
            />
          </Grid>
        </Grid>

        {/* <div class="row justify-content-center">
          <div class="col-md-5  key-facts-content  text-md-left">
            <h3 class="mx-5 px-5">KEY FACTS</h3>
            <h1 className="mx-5 px-5">
              Essential Insights At A Glance
              <br />
              Explore Our Key Facts.
            </h1>

            <div class="row facts-grid mt-5 pt-2 ">
              <div class="col-12 col-sm-6 col-md-4 fact-box">
                <img src={images.medicalstaff} alt="Medical Staff Icon" />
                <h2>50+</h2>
                <p>Medical staff</p>
              </div>
              <div class="col-12 col-sm-6 col-md-4 fact-box">
                <img src={images.icubed} alt="Beds Icon" />
                <h2>75+</h2>
                <p>Beds</p>
              </div>
              <div class="col-12 col-sm-6 col-md-4 fact-box">
                <img src={images.hospitalbed} alt="ICU Bed Icon" />
                <h2>25+</h2>
                <p>Bed ICU</p>
              </div>
            </div>
          </div>
          <div class="col-md-6 key-facts-image text-center">
            <img src={images.keyreactgle} alt="Tablet View" class="img-fluid" />
          </div>
        </div> */}
      </div>

      <div>
        <Booking />
      </div>
    </>
  );
};

export default Home;
