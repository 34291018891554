import React from "react";
import { images } from "../../Config/images"; // Make sure your image paths are correct
import "./AboutUs.css"; // Import your CSS file
import CommonBreadcrumbs from "../../componant/Breadcrumb/Breadcrumb";
import Booking from "../../componant/Booking/Booking";
import HomeBooking from "../../componant/HomeBooking/HomeBooking";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";

const AboutUs = () => {
  const breadcrumbsData = [
    { label: "Home", url: "/" },
    {
      label: "About Us",
      url: "/aboutus",
    },
  ];
  return (
    <>
      <div className="header-image">
        {/* Absolute Positioned Image */}
        <div className="absolute-image-container">
          <img
            src={images.contactslider} // Replace with your image source
            alt="Doctors"
            className="absolute-image"
          />
          <div className="image-text-about">
            <h1 className="text-about">About us</h1>
            <p className="text-health">
              Global Hospital <br />
              Trusted Care, Trusted Team
            </p>
            <div className="breadcrumb">
              <CommonBreadcrumbs items={breadcrumbsData} separator="›" />
            </div>
          </div>
        </div>
      </div>

      <HomeBooking noMargin />

      <div className="about-sec ">
        <Grid container spacing={3}>
          <Grid item xs={12} md={7} lg={7} sm={12}>
            <div>
              <h2 className="Experience-about-1">
                Experience world-class healthcare <br />
                <b>Global Spandan Hospitals</b>
              </h2>
              <p className="global-about mt-4">
                <p >
                  Global Hospital, Ahmedabad, is a premier multispecialty medical
                  center committed to delivering world-class healthcare services.
                  As a leading quaternary care facility in the region, we
                  specialize in a wide range of medical services, including
                  General and Minimal Access Surgery, Surgical Gastroenterology,
                  Medical Gastroenterology, General Medicine, Surgical and Medical
                  Oncology, Urosurgery, Nephrology, Orthopaedics and Joint
                  Replacement, Neuro and Spine Surgery, Neurology, ENT (Ear, Nose,
                  and Throat), Cardiology, Obstetrics and Gynaecology, and
                  Pulmonary and Critical Care.
                </p>
                <p >
                  Our expert team of doctors, nurses, and support staff are
                  dedicated to providing personalized care, using the latest
                  technologies and treatments to help patients recover quickly
                  and effectively.
                </p>
                <p >
                  Equipped with state-of-the-art imaging, pathology, and
                  laboratory services, we ensure accurate diagnostics and
                  effective treatment for all our patients. Global Hospital is
                  also fully equipped to handle emergencies and provides
                  comprehensive, coordinated care across all specialties.
                </p>
                <p>
                  We are committed to promoting the health and well-being of our
                  local community with preventive care packages and regular
                  health check-ups to help residents stay healthy and monitor
                  their well-being. At Global Hospital, safety, quality, and
                  innovation are at the forefront of everything we do, ensuring
                  that we continually set benchmarks for excellence in patient
                  care.
                </p>
              </p>
            </div>
          </Grid>
          <Grid item xs={12} md={5} lg={5} sm={12}>
            <div>
              <img
                src={images.hospitalabout}
                alt="Doctors Image"
                className="hospital-about-img"
              />
            </div>
          </Grid>
        </Grid>
      </div>

      <div>
        <h2 class="vison-h2 commit-sec text-center">
          We are committed to delivering your health aspirations
        </h2>
        <Grid container spacing={3} className="commit-sec">
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div className="aspirations-text">
              <h2 className="card-header card-asp-title">
                TODAY, OUR VISION IS A REALITY
              </h2>
              <div className="pt-3">
                <p class="vision-about">
                  Global Hospital is committed to redefining healthcare with innovation, compassion, and excellence. By integrating skilled clinicians, advanced technology, and holistic care, we deliver exceptional medical services across a wide range of specialties, including General and Minimal Access Surgery, Surgical and Medical Gastroenterology, Oncology, Neurology, and Cardiology.
                </p>
                <p class="vision-about">
                  Serving Ahmedabad and surrounding regions, we provide world-class tertiary and quaternary care, ensuring every patient receives the highest standards of treatment. As we grow, our mission remains focused on setting new benchmarks in healthcare through expertise, innovation, and compassion.
                </p>
                <p class="vision-about">
                  Our mission to set new benchmarks in patient care through innovation, expertise, and compassion.
                </p>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div className="aspirations-text">
              <h2 className="card-header card-asp-title">
                OUR APPROACH TO CARE IS UNMATCHED
              </h2>
              <div className="pt-3">
                <p class="vision-about">
                  Global Hospital redefines patient care by prioritizing the holistic well-being of every patient and their family. Beyond medical treatments, we focus on personalized, compassionate care tailored to individual needs. Our dedicated team of clinicians, nurses, and support staff is equipped with the resources and environment to deliver exceptional care.
                </p>
                <p class="vision-about">
                  We provide advanced treatments across specialties, including General and Minimal Access Surgery, Surgical and Medical Gastroenterology, Oncology, Urology, Nephrology, Orthopaedics and Joint Replacement, Neurology, Cardiology, Obstetrics and Gynaecology, and Pulmonary and Critical Care. With expertise in Trauma, Emergency, and Critical Care, as well as advanced surgery, we ensure comprehensive solutions for diverse medical needs.
                </p>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      <div className="commit-sec" style={{ paddingTop: "0px" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div className="about-vision">
              <div className="mask-about">
                <img className="mask-imgeq" src={images.maskimg} alt="" />
                <div className="vison-about-1">
                  <h2>Vision</h2>
                  <p className="Empowering">
                    Empowering healthier communities through compassionate care,
                    innovative technology, and strategic expansion, delivering
                    personalized experiences that exceed expectations.
                  </p>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div className="about-vision">
              <div className="mask-about">
                <img className="mask-imgeq" src={images.union} alt="" />
                <div className="vison-about-1">
                  <h2>Mission</h2>
                  <p className="Empowering">
                    To provide accessible, equitable, and exceptional care, leveraging technology and expertise to address the unique needs of our diverse community.
                  </p>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      <div className="mt-5 commit-sec">
        <h2 class="vison-h2 text-center">Our Core Values</h2>
        <p className="vision-1p text-center mb-4">
          {" "}
          At Global Hospital, our core values drive everything we do, ensuring the highest standards of care and compassion.{" "}
        </p>
        <Grid container spacing={2} justifyContent="center">
          {/* Cardiology Card */}
          <Grid item xs={12} sm={6} md={4} lg={2.4}>
            <Card className="specialties-card2">
              <CardMedia
                component="img"
                image={images.doctore1}
                alt="Cardiology"
                className="special-image-about"
              />
              <CardContent>
                <Typography variant="h6" className="specialty-heading">
                  Patients First
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className="pt-2"
                >
                  We put patients' needs first
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Dermatology Card */}
          <Grid item xs={12} sm={6} md={4} lg={2.4}>
            <Card className="specialties-card2">
              <CardMedia
                component="img"
                image={images.logomake}
                alt="Dermatology"
                className="special-image-about"
              />
              <CardContent>
                <Typography variant="h6" className="specialty-heading">
                  Integrity
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className="pt-2"
                >
                  We do the right thing
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Physiotherapy Card */}
          <Grid item xs={12} sm={6} md={4} lg={2.4}>
            <Card className="specialties-card2">
              <CardMedia
                component="img"
                image={images.bloodpressur}
                alt="Physiotherapy"
                className="special-image-about"
              />
              <CardContent>
                <Typography variant="h6" className="specialty-heading">
                  Empathy
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className="pt-2"
                >
                  We listen with our hearts
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Orthopaedics Card */}
          <Grid item xs={12} sm={6} md={4} lg={2.4}>
            <Card className="specialties-card2">
              <CardMedia
                component="img"
                image={images.oprationroom}
                alt="Orthopaedics"
                className="special-image-about"
              />
              <CardContent>
                <Typography variant="h6" className="specialty-heading">
                  Teamwork
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className="pt-2"
                >
                  We are better together
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Surgical Oncology Card */}
          <Grid item xs={12} sm={6} md={4} lg={2.4}>
            <Card className="specialties-card2">
              <CardMedia
                component="img"
                image={images.femaleworker}
                alt="Surgical Oncology"
                className="special-image-about"
              />
              <CardContent>
                <Typography variant="h6" className="specialty-heading">
                  Excellence
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className="pt-2"
                >
                  We champion continuous improvement and innovation
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
      <div className="commitment-sec mt-5">
        <div className="out-commit-sec">
          <Grid container spacing={5}>
            <Grid item md={4} xs={12} sm={12}>
              <h1>Our Commitment</h1>
              <p className="mt-5">
                We believe trust is the cornerstone of all our relationships and
                it starts with honoring our commitments. With our people, size,
                reach, and relentless pursuit of excellence, we commit to making
                a difference, one patient, one family, one touch at a time.
              </p>
            </Grid>
            <Grid item md={8} xs={12} sm={12}>
              <Grid container spacing={3}>
                <Grid item md={4} xs={12} sm={12}>
                  <Box className="patients-box">
                    <img src={images.patients} alt="" />
                    <h5 className="mt-4">
                      <b>To our patients & their loved ones</b>
                    </h5>
                    <p className="mt-3" style={{ lineHeight: "23px" }}>
                      We will put our patients' needs first & go the extra mile
                      to provide quality & exemplary care.
                    </p>
                  </Box>
                </Grid>
                <Grid item md={4} xs={12} sm={12}>
                  <Box className="patients-box">
                    <img src={images.shareholders} alt="" />
                    <h5 className="mt-4">
                      <b>To Our Shareholders</b>
                    </h5>
                    <p className="mt-3" style={{ lineHeight: "23px" }}>
                      We will build long-term sustainable growth and shareholder
                      value.
                    </p>
                  </Box>
                </Grid>
                <Grid item md={4} xs={12} sm={12}>
                  <Box className="patients-box">
                    <img src={images.people} alt="" />
                    <h5 className="mt-4">
                      <b>To Our People</b>
                    </h5>
                    <p className="mt-3" style={{ lineHeight: "23px" }}>
                      We will build a caring and inspiring environment for our
                      people to succeed and shape the future with us.
                    </p>
                  </Box>
                </Grid>
              </Grid>
              <Grid container spacing={3} className="mt-1">
                <Grid item md={4} xs={12} sm={12}>
                  <Box className="patients-box">
                    <img src={images.partners} alt="" />
                    <h5 className="mt-4">
                      <b>To Our Partners</b>
                    </h5>
                    <p className="mt-3" style={{ lineHeight: "23px" }}>
                      We will work together with respect & honesty for mutual
                      benefit.
                    </p>
                  </Box>
                </Grid>
                <Grid item md={4} xs={12} sm={12}>
                  <Box className="patients-box">
                    <img src={images.communities} alt="" />
                    <h5 className="mt-4">
                      <b>To Our Communities</b>
                    </h5>
                    <p className="mt-3" style={{ lineHeight: "23px" }}>
                      We will do good beyond our healthcare services.
                    </p>
                  </Box>
                </Grid>
                <Grid item md={4} xs={12} sm={12}>
                  <Box className="patients-box">
                    <img src={images.planet} alt="" />
                    <h5 className="mt-4">
                      <b>To Our Planet</b>
                    </h5>
                    <p className="mt-3" style={{ lineHeight: "23px" }}>
                      We will make choices that protect the environment &
                      preserve it for future generations.
                    </p>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>

      <div className="commit-sec mt-5">
        <Grid container spacing={2}>
          <Grid item md={6} sm={12} xs={12} style={{ marginTop: "100px" }} className="about-title">
            <div className="about-text">
              <h2 className="Experience-about-1">On your side</h2>
              <h2 style={{ fontSize: "36px" }}>
                For all your healthcare needs
              </h2>
              <p className="global-about mt-5">
                Delivering exceptional care across multiple specialties, the focus remains on empowering patients with the best medical solutions tailored to their individual needs. With a dedicated team and access to cutting-edge technology, each step of the journey prioritizes precision, compassion, and a commitment to optimal health outcomes.
              </p>
              <p className="global-about">
                Every patient receives care that seamlessly integrates advanced diagnostics, innovative treatments, and personalized attention.
              </p>
              <p className="global-about">
                Our compassionate approach extends beyond clinical excellence—we are here to guide, support, and reassure you and your loved ones throughout your healthcare experience.
              </p>
            </div>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <img
              class=" doctor-image"
              src={images.surgeryopration}
              alt=""
              className="hospital-about-img"
            />
          </Grid>
        </Grid>
      </div>

      <div className="commit-sec mt-5">
        <Grid container spacing={2}>
          <Grid item md={6} sm={12} xs={12}>
            <img
              class=" doctor-image"
              src={images.workrsurgical}
              alt=""
              className="hospital-about-img"
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <div className="about-text">
              <h2 className="Experience-about-1">Our Presence</h2>
              <p className="global-about mt-5">
                As a trusted name in healthcare, the presence extends far beyond offering treatments—it embodies a commitment to excellence and trust. With an emphasis on innovation, collaboration, and quality care, it serves as a beacon of hope for patients across all walks of life.
              </p>
              <p className="global-about">
                The multidisciplinary approach ensures a seamless experience, delivering superior results through coordinated, compassionate, and expert care.
              </p>
              <p className="global-about">
                Our vision extends beyond providing care today—it’s about paving the way for a healthier tomorrow. We actively invest in advanced medical technologies, green healthcare initiatives, and partnerships that empower us to bring world-class care closer to every individual.
              </p>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="Review-car">
        <div className="standard-car">
          <Grid container>
            <Grid item md={6} xs={12} sm={12}>
              <h2>Elevating the Standard of Patient Car</h2>
              <p className="mt-4 dummy-text">
                G - Good Governance: Ethical decision-making<br />
                L - Loyalty: Commitment and dedication<br />
                O - One-Team Approach: Collaboration<br />
                B - Benevolence: Kindness and generosity<br />
                A - Authenticity: Being genuine and true<br />
                L - Legacy: Building a lasting impact
              </p>
            </Grid>
            <Grid item md={6} xs={12} sm={12}>
              <img
                src={images.femaleDoctor}
                alt=""
                className="female-doctor"
                style={{ position: "absolute", top: "-36%", width: "550px" }}
              />
            </Grid>
          </Grid>
          <h2 className="mt-4">
            Putting Patients First: Rooted in Medical Heritage, Guided by A
            Vision for Transformation
          </h2>
          <Grid container spacing={5} className="mt-4">
            <Grid item xs={12} sm={12} md={6}>
              <div className="review-mark mt-4">
                <img src={images.quote} alt="" />
                <h6 className="mt-2 review-h2">
                  Champions of Holistic Healthcare & Management,Serving Both
                  India & the Global Community
                </h6>
                <div style={{ textAlign: "end" }}>
                  <img src={images.quote1} alt="" />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <p className="dummy-text">
                With a legacy of compassionate care and a commitment to innovation, we redefine healthcare by prioritizing patient well-being above all else. Every aspect of care is guided by a deep-rooted medical heritage and a vision to set new benchmarks in quality, collaboration, and service excellence.
                <br />
                <br />
                As champions of holistic healthcare and management, our approach seamlessly integrates advanced technology, expertise across multiple specialties, and personalized attention to ensure the best outcomes. With a forward-thinking perspective, we continue to evolve to meet the needs of the global community, transforming lives with every step.
              </p>
            </Grid>
          </Grid>
        </div>
      </div>

      <div className="specialtiessa-container">
        {/* <img className="waves" src={images.waves}></img> */}
        <h3 className="text-center mb-5">Quality Standards</h3>
        <div className="main-about-catogory">
          <Grid container spacing={2} justifyContent="center">
            {/* Cardiology Box */}
            <Grid item xs={12} sm={6} md={4} lg={2.4}>
              <Box className="specialties-box-about">
                <img
                  src={images.excellenceInfra}
                  alt="Cardiology"
                  className="special-image-surgery"
                />
                <Typography variant="h6" className="specialty-heading">
                  Excellence in <br /> Infrastructure
                </Typography>
              </Box>
            </Grid>

            {/* Dermatology Box */}
            <Grid item xs={12} sm={6} md={4} lg={2.4}>
              <Box className="specialties-box-about">
                <img
                  src={images.patientwell}
                  alt="Dermatology"
                  className="special-image-surgery"
                />
                <Typography variant="h6" className="specialty-heading">
                  Prioritising
                  <br />
                  Patient Well-being
                </Typography>
              </Box>
            </Grid>

            {/* Physiotherapy Box */}
            <Grid item xs={12} sm={6} md={4} lg={2.4}>
              <Box className="specialties-box-about">
                <img
                  src={images.safeHealing}
                  alt="Physiotherapy"
                  className="special-image-surgery"
                />
                <Typography variant="h6" className="specialty-heading">
                  Crafting Safe
                  <br />
                  Healing Spaces
                </Typography>
              </Box>
            </Grid>

            {/* Orthopaedics Box */}
            <Grid item xs={12} sm={6} md={4} lg={2.4}>
              <Box className="specialties-box-about">
                <img
                  src={images.excellence}
                  alt="Orthopaedics"
                  className="special-image-surgery"
                />
                <Typography variant="h6" className="specialty-heading">
                  Relentless Pursuit
                  <br />
                  of Excellence
                </Typography>
              </Box>
            </Grid>

            {/* Add New Box */}
            <Grid item xs={12} sm={6} md={4} lg={2.4}>
              <Box className="specialties-box-about">
                <img
                  src={images.feedback}
                  alt="New Specialty"
                  className="special-image-surgery"
                />
                <Typography variant="h6" className="specialty-heading">
                  Embracing Constructive
                  <br />
                  Feedback
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>

      <Booking />
    </>
  );
};

export default AboutUs;
