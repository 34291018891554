import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "../componant/Header/Header";
import Home from "../pages/Home/Home";
import AboutUs from "../pages/AbouUs/AboutUs";
import Specialties from "../pages/Specialties/Specialties";
import Contact from "../pages/Contact/Contact";
import Navbar from "../componant/Navbar/Navbar";
import Footer from "../componant/Footer/Footer";
import DoctorList from "../pages/DoctorList/DoctorList";
import ViewProfile from "../pages/ViewProfile/ViewProfile";

const Routers = () => {
  return (
    <Router>
      <Header />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/specialties" element={<Specialties />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/doctor-list" element={<DoctorList />} />
        <Route path="/view-profile" element={<ViewProfile />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default Routers;
